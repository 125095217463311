.ql-editor {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
}
.readOnly .ql-editor{
    margin: 0;
    font-size: 14px;
}
.readOnly .ql-container.ql-disabled .ql-tooltip, .readOnly .ql-container.ql-disabled .ql-tooltip{
    display: none;
}